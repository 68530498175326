// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-createnewseason-js": () => import("./../../../src/pages/createnewseason.js" /* webpackChunkName: "component---src-pages-createnewseason-js" */),
  "component---src-pages-divisions-js": () => import("./../../../src/pages/divisions.js" /* webpackChunkName: "component---src-pages-divisions-js" */),
  "component---src-pages-editdivision-js": () => import("./../../../src/pages/editdivision.js" /* webpackChunkName: "component---src-pages-editdivision-js" */),
  "component---src-pages-editmatch-js": () => import("./../../../src/pages/editmatch.js" /* webpackChunkName: "component---src-pages-editmatch-js" */),
  "component---src-pages-editseason-js": () => import("./../../../src/pages/editseason.js" /* webpackChunkName: "component---src-pages-editseason-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lapplivestream-js": () => import("./../../../src/pages/lapplivestream.js" /* webpackChunkName: "component---src-pages-lapplivestream-js" */),
  "component---src-pages-live-js": () => import("./../../../src/pages/live.js" /* webpackChunkName: "component---src-pages-live-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-manageteams-js": () => import("./../../../src/pages/manageteams.js" /* webpackChunkName: "component---src-pages-manageteams-js" */),
  "component---src-pages-scoreboard-js": () => import("./../../../src/pages/scoreboard.js" /* webpackChunkName: "component---src-pages-scoreboard-js" */),
  "component---src-pages-signup-js": () => import("./../../../src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-teamscoreboard-js": () => import("./../../../src/pages/teamscoreboard.js" /* webpackChunkName: "component---src-pages-teamscoreboard-js" */),
  "component---src-pages-viewseasons-js": () => import("./../../../src/pages/viewseasons.js" /* webpackChunkName: "component---src-pages-viewseasons-js" */)
}

